import React, { createContext, useContext, useMemo, useState } from "react";
import { GetHeaderRes } from "../network/UserApi/userInterfaces";

type IAuthContext = [
  GetHeaderRes,
  React.Dispatch<React.SetStateAction<GetHeaderRes>>
];

const AuthContext = createContext<IAuthContext>([
  {
    id: undefined,
    name: undefined,
    fxCode: undefined,
  },
  () => null,
]);

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

function AuthProvider(props: any) {
  const [auth, setAuth] = useState<GetHeaderRes>({
    id: undefined,
    name: undefined,
    fxCode: undefined,
  });

  const value = useMemo(() => [auth, setAuth], [auth]);

  return <AuthContext.Provider value={value} {...props} />;
}

export { AuthProvider, useAuth };
