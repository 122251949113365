import AdminChart from "../components/admin/AdminChart";
import AdminOverall from "../components/admin/AdminOverall";

interface IProps {}

const AdminPage = (props: IProps) => {
  return (
    <div className="defaultLayout">
      <div className="font-bold mb-4">
        <p>Все счета</p>
      </div>

      <div>
        <AdminOverall />
        <div className="flex flex-col mt-10">
          <p className="mb-4">Графики за все время</p>
          <AdminChart type={"amountCompound"} desc="Сложный процент" />
          <AdminChart type={"factInc"} desc="Фактический процент" />
          <AdminChart type={"amountMins"} desc="Заработано" />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
