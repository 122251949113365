import React, { useState } from "react";
import Input from "../components/widgets/Input";
import { GetHeaderRes } from "../network/UserApi/userInterfaces";

interface IProps {
  header: GetHeaderRes;
}

const UserPage = (props: IProps) => {
  const [accounts, setAccounts] = useState(props.header.accounts || []);
  const [copied, setCopied] = useState(false);
  const [copyType, setCopyType] = useState("");

  const handleCopy = (text: string, type: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyType(type);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Сброс сообщения после 2 секунд
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleDeleteAccount = (accountToDelete: string) => {
    // Обновляем список аккаунтов после удаления
    const updatedAccounts = accounts.filter(
      (acc) => acc.account !== accountToDelete
    );
    setAccounts(updatedAccounts);
  };

  const renderedAccs = accounts?.map((acc) => {
    return (
      <Input
        key={acc.account}
        text={acc.name ? acc.name : acc.account}
        className="max-w-[300px] mb-4"
        rename={true}
        acc={acc.account}
        onDelete={() => handleDeleteAccount(acc.account)} // Передаем коллбэк для удаления
      />
    );
  });

  return (
    <div className="defaultLayout">
      <div className="flex justify-between flex-col gap-2">
        <p>
          <b>Имя:</b> {props.header.name}
        </p>
        <div>
          <div>
            <p>
              <b>Аккаунты:</b>
            </p>
            <p className="font-thin mt-2 mb-4">
              Здесь вы можете заменить имя для роботов, обращаем ваше внимание,
              что нет необходимости писать последние 4 цифры счета в названии,
              мы это делаем автоматически
            </p>
          </div>

          {renderedAccs}
        </div>
        <div className="flex flex-row gap-1">
          <b>FxCode: </b>
          <p
            className="cursor-pointer"
            onClick={() => handleCopy(props.header.fxCode || "", "FxCode")}
          >
            {props.header.fxCode}
          </p>
        </div>
        <div className="flex flex-row gap-1">
          <b>Реферальный код: </b>
          <p
            className="cursor-pointer"
            onClick={() =>
              handleCopy(props.header.refCode || "", "Реферальный код")
            }
          >
            {props.header.refCode}
          </p>
        </div>
        <p className={`text-green-500 ${copied ? "" : "invisible"}`}>
          {copyType} скопирован!
        </p>
        <div className="mt-4">
          <a
            href={`${process.env.PUBLIC_URL}/AIR Monitoring.ex4`}
            download="AIR Monitoring.ex4"
            className="text-blue-500 underline cursor-pointer"
          >
            Скачать плагин
          </a>
          <p className="text-sm">обновлено: 08.10.2024 17:23</p>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
