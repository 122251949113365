import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
} from "recharts";
import { Detail } from "../network/TableApi/tableInterfaces";

interface IProps {
  details: Detail[];
}

const MonthlyMarginChart: React.FC<IProps> = ({ details }) => {
  if (!details) {
    console.error("No details provided to MonthlyMarginChart");
    return null; // Возвращаем null или какой-то заполнитель, если данные не доступны
  }

  // Преобразуем строковые даты в объекты Date
  const groupedDetails: { [key: string]: Detail } = {};
  details.forEach((detail) => {
    // Проверяем и преобразуем createdAt в объект Date, если это строка
    const createdAtDate =
      typeof detail.createdAt === "string"
        ? new Date(detail.createdAt)
        : detail.createdAt;

    const day = createdAtDate.toISOString().substring(0, 10); // Теперь createdAt всегда объект Date
    if (!groupedDetails[day] || detail.margin < groupedDetails[day].margin) {
      groupedDetails[day] = detail;
    }
  });

  // Сортируем дни в правильном порядке
  const sortedDays = Object.keys(groupedDetails).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  const chartData = sortedDays.map((day) => ({
    date: day,
    margin: groupedDetails[day].margin,
  }));

  return (
    <div>
      <p>
        <b>График маржи по дням за месяц</b>
      </p>
      <BarChart
        width={950}
        height={400}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={[0, "auto"]} />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="margin" name="Маржа">
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getMarginColor(entry.margin)} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

const getMarginColor = (margin: number) => {
  if (margin > 1000) return "#3ac47d";
  if (margin >= 500) return "#f7b924";
  return "#d92550";
};

export default MonthlyMarginChart;
