import { Select } from "antd";

interface Portfolio {
  id: string;
  name?: string; // Свойство name может быть string или undefined
}

interface IProps {
  portfolios: Portfolio[];
  selected: string | undefined;
  onChange: (value: string) => void;
}

const ProtfolioSelector = ({ portfolios, selected, onChange }: IProps) => {
  return (
    <Select
      value={selected}
      onChange={onChange}
      style={{ width: 200 }}
      placeholder="Выберите портфель"
    >
      {portfolios.map((portfolio) => (
        <Select.Option key={portfolio.id} value={portfolio.id}>
          {portfolio.name || "Без названия"}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProtfolioSelector;
