import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../network/UserApi/userApi";
import AppRoutes from "../structure/routes";
import Input from "../components/widgets/Input";
import Button from "../components/widgets/Button";
import { isValidEmail } from "../utils/functions";

interface IProps {}

const RegisterPage = (props: IProps) => {
  const [userInfo, setUserInfo] = useAuth();
  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [refCode, setRefCode] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleRegister = (mail: string, password: string) => {
    registerUser({ email: mail, password: password, name: name })
      .then((r) => {
        if (r.status === "success") {
          setUserInfo(r);
          navigate(AppRoutes.UserCabinet);
        }
      })
      .catch((e) => {
        console.log(e);

        setError(true);
      });
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className="flex justify-center items-center min-h-screen w-full flex-col">
      <Input
        variant="time"
        label="Электронная почта"
        onInput={setMail}
        className=""
      />
      <Input variant="time" label="Имя" onInput={setName} className="" />
      <Input
        variant="time"
        label="Пароль"
        type="password"
        onInput={setPassword}
      />
      <Input
        variant="time"
        label="Пароль еще раз"
        type="password"
        onInput={setPasswordConfirmation}
      />
      <Input
        variant="time"
        label="Код друга"
        type="text"
        onInput={setRefCode}
      />
      <p
        className={`text-red-700 mb-2 transition ${
          error ? "ease-out" : "ease-out invisible"
        } `}
      >
        {password !== passwordConfirmation
          ? "Пароли не совпадают"
          : !isValidEmail(mail)
          ? "Невалидная почта"
          : "Ошибка регистрации, возможно почта уже занята"}
      </p>
      <Button
        className={`${error ? " bg-red-700" : "bg-black"}`}
        onClick={() => {
          handleRegister(mail, password);
        }}
      >
        Войти
      </Button>
      <div className="flex flex-row">
        <p>
          Уже есть аккаунт?
          <Link to={AppRoutes.LoginPage}>
            <p className="font-bold">Залогиньтесь</p>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
