import YearlyIncome from "../components/YearlyIncome";

interface IProps {}

const IncomePage = (props: IProps) => {
  return (
    <div className="defaultLayout">
      <YearlyIncome />
    </div>
  );
};

export default IncomePage;
