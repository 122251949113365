import { useEffect, useState } from "react";
import { getPortfolio, getPortfolios } from "../network/TableApi/tableApi";
import {
  PortfolioRes,
  SinglePortfolioRes,
} from "../network/TableApi/tableInterfaces";
import ProtfolioSelector from "../components/Portfolio/ProtfolioSelector";
import PortfolioChart from "../components/Portfolio/PortfolioChart";
import PortfolioTable from "../components/Portfolio/PortfolioTable";
import PortfolioModal from "../components/Portfolio/PortfolioModal";
import { getHeader } from "../network/UserApi/userApi";
import { useAuth } from "../providers/AuthProvider";
import EditPortfolioModal from "../components/Portfolio/EditPortfolioModal";
import YearlyIncome from "../components/YearlyIncome";
import {
  AccountMarginAndDrawdown,
  GetMarginAndDrawdownRes,
} from "../network/SumTableApi/SumTableInterfaces";
import { getMarginAndDrawdown } from "../network/SumTableApi/SumTableApi";
import YearlyMargin from "../components/YearlyMargin";
import YearlyDrawdown from "../components/YearlyDrawdown";
import MarginAndDrawdown from "../components/Portfolio/MarginAndDrawdown";

interface IProps {}

const MyPortfolioPage = (props: IProps) => {
  const [portfolios, setPortfolios] = useState<PortfolioRes | null>(null);
  const [selected, setSelected] = useState<string | undefined>();
  const [name, setName] = useState<string>("Название портфеля");
  const [loaded, setLoaded] = useState<SinglePortfolioRes | undefined>();
  const [reloadKey, setReloadKey] = useState<number>(0);
  const [userInfo, setUserInfo] = useAuth();
  const [loadingPortfolio, setLoadingPortfolio] = useState<boolean>(false);
  const [tableData, setTableData] = useState<AccountMarginAndDrawdown[]>([]);
  const [accounts, setAccounts] = useState<string[]>([]);

  function getUserPortfolios(id?: string) {
    getPortfolios().then((res: PortfolioRes) => {
      setPortfolios(res);
      if (res.data.length > 0) {
        const portfolioIds = res.data.map((portfolio) => portfolio.id);
        const newSelectedId =
          id && portfolioIds.includes(id) ? id : res.data[0].id;
        setSelected(newSelectedId);
      } else {
        setSelected(undefined);
      }
    });
  }

  useEffect(() => {
    console.log("Selected portfolio ID:", selected);
    if (selected) {
      setLoaded(undefined); // Очищаем данные перед загрузкой новых
      setLoadingPortfolio(true); // Начинаем загрузку
      getPortfolio({ id: selected })
        .then((res: SinglePortfolioRes) => {
          setName(res.data.name || "");
          setLoaded(res);
        })
        .finally(() => {
          setLoadingPortfolio(false); // Завершаем загрузку
        });
    } else {
      setName("");
      setLoaded(undefined);
      setLoadingPortfolio(false);
    }
  }, [selected, reloadKey]);

  useEffect(() => {
    getUserPortfolios();
    getHeader().then((r) => {
      setUserInfo(r);
    });
  }, []);

  const strAccounts = loaded?.accounts.map((e) => e.account.account);

  return (
    <div className="defaultLayout min-w-[900px]">
      <div className="flex items-center gap-4">
        <div>
          {portfolios ? (
            portfolios.data.length > 0 ? (
              <div>
                <p>Выберите портфель:</p>
                <ProtfolioSelector
                  portfolios={portfolios.data}
                  selected={selected}
                  onChange={(e) => {
                    setSelected(e);
                  }}
                />
              </div>
            ) : (
              <p>
                У вас нет созданных портфелей. Создайте новый портфель,
                используя кнопку ниже.
              </p>
            )
          ) : (
            <p>Загрузка...</p>
          )}
        </div>
        {userInfo.accounts && (
          <div>
            <PortfolioModal
              accounts={userInfo.accounts}
              onCreate={(e) => {
                if (e) {
                  getUserPortfolios();
                }
              }}
            />
          </div>
        )}
        {selected && userInfo.accounts && (
          <>
            {loaded ? (
              <EditPortfolioModal
                id={loaded.data.id}
                name={loaded.data.name || ""}
                loaded={loaded.accounts}
                accounts={userInfo.accounts}
                onCreate={(e) => {
                  if (e) {
                    getUserPortfolios();
                    setReloadKey((prevKey) => prevKey + 1);
                  }
                }}
              />
            ) : (
              loadingPortfolio && <p>Загрузка данных портфеля...</p>
            )}
          </>
        )}
      </div>
      {selected && loaded && (
        <div className="defaultLayout">
          <div className="font-bold text-lg mb-4">
            <h2>Портфель: {name}</h2>
          </div>
          <div>
            <PortfolioTable key={reloadKey} id={selected} />
            <div className="flex flex-col mt-10">
              <p className="mb-4">Графики за все время</p>
              <PortfolioChart
                key={`${reloadKey}-amountCompound`}
                id={selected}
                type={"amountCompound"}
                desc="Сложный процент"
              />
              <PortfolioChart
                key={`${reloadKey}-factInc`}
                id={selected}
                type={"factInc"}
                desc="Фактический процент"
              />
              <PortfolioChart
                key={`${reloadKey}-amountMins`}
                id={selected}
                type={"amountMins"}
                desc="Заработано"
              />
              <PortfolioChart
                key={`${reloadKey}-balanceBefore`}
                id={selected}
                type={"balanceBefore"}
                desc="Баланс"
              />
            </div>
          </div>
          <div>
            {loaded && strAccounts && (
              <div>
                <div className="mb-8">
                  <p className="font-bold mb-2">Таблица доходов</p>
                  <YearlyIncome accounts={strAccounts} />
                </div>
                <div className="mb-8">
                  <MarginAndDrawdown accounts={strAccounts} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPortfolioPage;
