import React, { useCallback, useEffect, useState } from "react";
import { StringObject } from "../../types";
import { AC } from "../../utils/style";
import { renameAccount } from "../../network/UserApi/userApi";
import { deleteAccount } from "../../network/TableApi/tableApi";

type InputType = "transparent" | "time";

interface InputProps {
  variant?: InputType;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  text?: string;
  className?: string;
  label?: string;
  rename?: boolean;
  acc?: string;
  onInput?: (value: string) => void;
  onDelete?: () => void;
}

const BASE_INPUT_STYLE = "focus:outline-none";

const DEFAULT_VARIANT: InputType = "time";
const DEFAULT_TYPE: React.HTMLInputTypeAttribute = "text";

const TYPOGRAPHY_STYLES: StringObject<string> = {
  transparent:
    "font-medium text-md text-text bg-transparent placeholder:text-sm placeholder:font-medium placeholder:text-text-placeholder",
  time: "p-3 font-medium text-md text-text bg-main-background border-main-gray border-[1px] rounded-lg placeholder:text-sm placeholder:font-medium placeholder:text-text-placeholder",
};

const Input = (props: InputProps) => {
  const [text, setText] = useState<string>(props.text || "");
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const style = TYPOGRAPHY_STYLES[props.variant || DEFAULT_VARIANT];

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.rename) {
        setShowButton(true);
      }

      setText(event.currentTarget.value);
    },
    [props.rename]
  );

  const saveChanges = () => {
    if (props.acc) {
      renameAccount({ account: props.acc, newName: text }).then(() => {
        setShowButton(false);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      });
    }
  };

  const handleBlur = () => {
    if (showButton) {
      saveChanges();
    }
  };

  const handleDelete = () => {
    if (props.acc) {
      const confirmed = window.confirm(
        "Вы действительно хотите удалить аккаунт?"
      );
      if (confirmed) {
        deleteAccount({ account: props.acc }).then((response) => {
          if (response.success) {
            alert("Аккаунт успешно удален!");
            if (props.onDelete) {
              props.onDelete();
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (props.onInput) props.onInput(text);
  }, [text, props]);

  return (
    <div className="flex flex-col relative">
      {props.label && <label className="font-bold mb-1">{props.label}</label>}
      <div className="relative group flex flex-row items-center">
        <input
          placeholder={props.placeholder}
          type={props.type || DEFAULT_TYPE}
          className={AC(BASE_INPUT_STYLE, style, props.className)}
          onChange={onChange}
          onBlur={handleBlur}
          value={text}
        />
        {props.acc && (
          <div className="ml-2 self-center text-sm text-gray-500">
            {props.acc}
          </div>
        )}
        {props.acc && (
          <>
            {showButton && (
              <button
                className="ml-4 px-4 py-2 bg-blue-500 text-white rounded"
                onClick={saveChanges}
              >
                Сохранить
              </button>
            )}
            <button
              className="absolute right-0 px-4 py-2 bg-red-500 text-white rounded opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDelete}
            >
              Удалить
            </button>
          </>
        )}
        {showSuccessMessage && (
          <div className="absolute top-0 left-0 right-0 bg-green-100 text-green-700 py-1 px-3 rounded mt-2">
            Успешное изменение!
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
