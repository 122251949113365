import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../config";
import { useAuth } from "../providers/AuthProvider";
import { getHeader } from "../network/UserApi/userApi";
import ImportSelector from "../components/Import/ImportSelector";

interface IProps {}

const ImportPage = (props: IProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>("");
  const [userInfo, setUserInfo] = useAuth();
  const [selected, setSelected] = useState<string>();

  // Обработчик изменения файла
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };
  console.log(userInfo);
  // Обработчик отправки файла
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selected && !file?.name.includes(selected)) {
      setMessage("Вы выбрали не тот файл, провеьте счет");
      return;
    }
    if (!file) {
      setMessage("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${baseURL}/api/user/uploadFile?account=${selected}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
      setMessage("Failed to upload file");
    }
  };

  useEffect(() => {
    getHeader().then((r) => {
      setUserInfo(r);
    });
  }, []);

  return (
    <div className="defaultLayout">
      <div>
        {userInfo && userInfo.accounts && (
          <div className="mb-6">
            <p>Выберите Аккаунт</p>
            <ImportSelector
              accounts={userInfo.accounts}
              onChange={(e) => {
                setSelected(e);
              }}
            />
            <p>Это нужно для проверки того, чтоб вы отправили нужный файл</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
          <button type="submit" className="defaultButton" disabled={false}>
            Загрузить
          </button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ImportPage;
