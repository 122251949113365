import RobotEndpoints from "../../structure/robotEndpoints";
import { request } from "../mainRequest";
import {
  changeRobotPercentModeReq,
  changeRobotPercentModeRes,
  RobotInfoReq,
  RobotInfoRes,
} from "./robotInterfaces";

export function getRobotInfo(data: RobotInfoReq): Promise<RobotInfoRes> {
  return request(RobotEndpoints.GetRobotInfo, data);
}

export function changeRobotPercentMode(
  data: changeRobotPercentModeReq
): Promise<changeRobotPercentModeRes> {
  return request(RobotEndpoints.ChangeRobotPercentMode, data);
}
