import clsx from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Merge classes with exclude dublicates tailwind params
 * @param classes - class string array
 * @returns class tring
 */
export function AC(...classes: (string | undefined)[]) {
  return twMerge(clsx(classes));
}
