interface IProps {}

const Header = (props: IProps) => {
  return (
    <header className="h-[70px] bg-[#f0f0f0] w-full shadow-sm flex items-center">
      <div className="flex items-center justify-center w-full text-[50px] pl-6">
        <h1>FXBALANCE</h1>
      </div>
    </header>
  );
};

export default Header;
