import UserEndpoints from "../../structure/userEndpoints";
import { request } from "../mainRequest";
import {
  GetHeaderRes,
  RenameAccReq,
  UserRegisterReq,
  UserRegisterRes,
} from "./userInterfaces";

export function registerUser(data: UserRegisterReq): Promise<UserRegisterRes> {
  return request(UserEndpoints.UserRegister, data);
}

export function loginUser(data: UserRegisterReq): Promise<UserRegisterRes> {
  return request(UserEndpoints.UserLogin, data);
}

export function logoutUser(): Promise<string> {
  return request(UserEndpoints.UserLogout);
}

export function getHeader(): Promise<GetHeaderRes> {
  return request(UserEndpoints.GetHeader);
}

export function renameAccount(data: RenameAccReq): Promise<GetHeaderRes> {
  return request(UserEndpoints.RenameAcc, data);
}
