import { useEffect, useState } from "react";
import { Table, Switch, Button, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { createPath } from "../../utils/linkHandler";
import AppRoutes from "../../structure/routes";
import { formatDate } from "../../utils/functions";
import { changePercMode } from "../../network/TableApi/tableApi";
import { getPortfolioTable } from "../../network/PortfolioApi/portfolioApi";
import { GetPortfolioTableRes } from "../../network/PortfolioApi/portfolioInterfaces";
import "./PortfolioTable.css"; // Импортируем кастомный CSS

const { Text } = Typography;

interface PortfolioTableRow {
  key: string;
  account: string;
  name: string;
  startDate: string | null;
  factIncomeValue: number;
  factIncomeMoney: number;
  absoluteIncomeValue: number;
  absoluteIncomeMoney: number;
  monthlyFactIncomeValue: number;
  monthlyFactIncomeMoney: number;
  lastMonthFactIncomeValue: number;
  lastMonthFactIncomeMoney: number;
  weeklyFactIncomeValue: number;
  weeklyFactIncomeMoney: number;
  lastWeekFactIncomeValue: number;
  lastWeekFactIncomeMoney: number;
  isTotalRow?: boolean;
}

interface IProps {
  id: string;
  external?: boolean;
  percMode?: boolean;
}

const PortfolioTable = (props: IProps) => {
  const [tableData, setTableData] = useState<PortfolioTableRow[]>([]);
  const [percMode, setPercMode] = useState<boolean>(props.percMode || false);
  const [money, setMoney] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!props.id) {
      setError("Портфель не выбран");
      setLoading(false);
      return;
    }

    setLoading(true);
    getPortfolioTable({ id: props.id })
      .then((res: GetPortfolioTableRes) => {
        if (res.success) {
          // Обновляем процентный режим
          setPercMode(res.isOnlyPerc);

          const dataRows = res.data.map((row) => ({
            key: row.account!,
            account: row.account!,
            name: row.name,
            startDate: row.startDate
              ? formatDate(row.startDate.toString().split("T")[0])
              : null,
            factIncomeValue: row.factIncomeValue,
            factIncomeMoney: row.factIncomeMoney,
            absoluteIncomeValue: row.absoluteIncomeValue,
            absoluteIncomeMoney: row.absoluteIncomeMoney,
            monthlyFactIncomeValue: row.monthlyFactIncomeValue,
            monthlyFactIncomeMoney: row.monthlyFactIncomeMoney,
            lastMonthFactIncomeValue: row.lastMonthFactIncomeValue,
            lastMonthFactIncomeMoney: row.lastMonthFactIncomeMoney,
            weeklyFactIncomeValue: row.weeklyFactIncomeValue,
            weeklyFactIncomeMoney: row.weeklyFactIncomeMoney,
            lastWeekFactIncomeValue: row.lastWeekFactIncomeValue,
            lastWeekFactIncomeMoney: row.lastWeekFactIncomeMoney,
            isTotalRow: false,
          }));

          // Добавляем общую строку
          if (res.overall) {
            dataRows.push({
              key: "total",
              account: "",
              name: "Итого",
              startDate: res.overall.startDate
                ? formatDate(res.overall.startDate.toString().split("T")[0])
                : null,
              factIncomeValue: res.overall.factIncomeValue,
              factIncomeMoney: res.overall.factIncomeMoney,
              absoluteIncomeValue: res.overall.absoluteIncomeValue,
              absoluteIncomeMoney: res.overall.absoluteIncomeMoney,
              monthlyFactIncomeValue: res.overall.monthlyFactIncomeValue,
              monthlyFactIncomeMoney: res.overall.monthlyFactIncomeMoney,
              lastMonthFactIncomeValue: res.overall.lastMonthFactIncomeValue,
              lastMonthFactIncomeMoney: res.overall.lastMonthFactIncomeMoney,
              weeklyFactIncomeValue: res.overall.weeklyFactIncomeValue,
              weeklyFactIncomeMoney: res.overall.weeklyFactIncomeMoney,
              lastWeekFactIncomeValue: res.overall.lastWeekFactIncomeValue,
              lastWeekFactIncomeMoney: res.overall.lastWeekFactIncomeMoney,
              isTotalRow: true,
            });
          }

          setTableData(dataRows);
          setError(null);
        } else {
          setError("Ошибка загрузки данных");
        }
      })
      .catch((err) => {
        console.error("Ошибка загрузки данных:", err);
        const errorMessage =
          err.response && err.response.status === 404
            ? "Портфель не найден"
            : "Ошибка загрузки данных";
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.id]);

  const getColor = (value: number) => {
    return value >= 0 ? "green" : "red";
  };

  // Функция для обработки переключения percMode
  const handlePercModeChange = (checked: boolean) => {
    setPercMode(checked);
    changePercMode({ mode: checked, id: props.id })
      .then((res) => {
        if (res.success) {
          message.success("Режим успешно изменен");
        } else {
          message.error("Не удалось изменить режим");
        }
      })
      .catch(() => {
        message.error("Ошибка при изменении режима");
      });
  };

  // Обработка копирования ссылки в буфер обмена
  const handleCopyLink = () => {
    const link = `${window.location.origin}/portfolio?id=${props.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success("Ссылка скопирована в буфер обмена");
      })
      .catch(() => {
        message.error("Не удалось скопировать ссылку");
      });
  };

  const columns: ColumnsType<PortfolioTableRow> = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (_: any, record: PortfolioTableRow) => {
        if (record.isTotalRow) {
          return <b>{record.name}</b>;
        }

        const displayName = record.name
          ? `${record.name} ${record.account.slice(-4)}`
          : record.account;

        return props.external ? (
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.PublicRobot,
              args: [{ key: "id", value: record.account }],
            })}
          >
            <b>{displayName}</b>
          </Link>
        ) : (
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.RobotPage,
              args: [{ key: "id", value: record.account }],
            })}
          >
            <b>{displayName}</b>
          </Link>
        );
      },
    },
    {
      title: "Дата старта",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string | null) => (date ? date : "-"),
    },
    {
      title: "Фактический доход",
      dataIndex: "factIncomeValue",
      key: "factIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.factIncomeMoney / 100 // Делим на 100
          : record.factIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Абсолютный доход",
      dataIndex: "absoluteIncomeValue",
      key: "absoluteIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.absoluteIncomeMoney / 100 // Делим на 100
          : record.absoluteIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за месяц",
      dataIndex: "monthlyFactIncomeValue",
      key: "monthlyFactIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.monthlyFactIncomeMoney / 100 // Делим на 100
          : record.monthlyFactIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за прошлый месяц",
      dataIndex: "lastMonthFactIncomeValue",
      key: "lastMonthFactIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.lastMonthFactIncomeMoney / 100 // Делим на 100
          : record.lastMonthFactIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за неделю",
      dataIndex: "weeklyFactIncomeValue",
      key: "weeklyFactIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.weeklyFactIncomeMoney / 100 // Делим на 100
          : record.weeklyFactIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за прошлую неделю",
      dataIndex: "lastWeekFactIncomeValue",
      key: "lastWeekFactIncomeValue",
      align: "right",
      render: (_: any, record: PortfolioTableRow) => {
        const value = money
          ? record.lastWeekFactIncomeMoney / 100 // Делим на 100
          : record.lastWeekFactIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = { color: getColor(value) };
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
  ];

  if (loading) {
    return <Text>Загрузка...</Text>;
  }

  if (error) {
    return <Text type="danger">{error}</Text>;
  }

  // Ссылка для копирования
  const portfolioLink = `${window.location.origin}/portfolio?id=${props.id}`;

  return (
    <div>
      {!props.external && (
        <div className="mt-4">
          <Text
            copyable={{ text: portfolioLink }}
            onClick={() => {
              handleCopyLink();
            }}
            className="cursor-pointer text-blue-500 underline"
          >
            {portfolioLink}
          </Text>
        </div>
      )}
      {!props.external && (
        <div className="flex justify-between mb-4">
          <div className="flex items-center">
            <Switch
              checked={money}
              onChange={() => setMoney(!money)}
              checkedChildren="Деньги"
              unCheckedChildren="Проценты"
              className="custom-switch bg-slate-400"
            />
            <span className="ml-2 text-black">Показывать в деньгах</span>
          </div>
          <div className="flex items-center space-x-4">
            <Button
              type="primary"
              onClick={handleCopyLink}
              className="custom-button"
            >
              Копировать ссылку
            </Button>
            <Switch
              checked={percMode}
              onChange={handlePercModeChange}
              checkedChildren="Процентный режим"
              unCheckedChildren="Обобщенный режим"
              className=" bg-slate-400"
            />
            <span className="text-black">Процентный режим</span>
          </div>
        </div>
      )}

      <Table<PortfolioTableRow>
        dataSource={tableData}
        columns={columns}
        pagination={false}
        rowKey="key"
        loading={loading}
        scroll={{ x: true }}
        size="small" // Компактный режим
        style={{ maxWidth: 900 }} // Ограничиваем ширину таблицы
        locale={{
          emptyText: loading ? "Загрузка..." : "Нет данных",
        }}
        rowClassName={(record) =>
          record.isTotalRow ? "bg-gray-100 font-bold" : ""
        }
      />

      {/* Добавляем ссылку под таблицей */}
    </div>
  );
};

export default PortfolioTable;
