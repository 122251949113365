import TableEndpoints from "../../structure/tableEndpoints";
import { request } from "../mainRequest";
import {
  GetIncomeResponse,
  GetMarginAndDrawdownReq,
  GetMarginAndDrawdownRes,
  TodayTableData,
} from "./SumTableInterfaces";

export function getMarginAndDrawdown(
  data?: GetMarginAndDrawdownReq
): Promise<GetMarginAndDrawdownRes> {
  return request(TableEndpoints.GetMarginAndDrawdown, data);
}

export function getIncomeData(data: {
  accounts?: Array<string>;
}): Promise<GetIncomeResponse> {
  return request(TableEndpoints.GetIncome, data);
}

export function getTodayTableData(): Promise<TodayTableData> {
  return request(TableEndpoints.GetTodayTable);
}
