import UserEndpoints from "../../structure/userEndpoints";
import { request } from "../mainRequest";
import {
  ChangePercModeResponse,
  createPortfolioReq,
  Order,
  OverallChartRes,
  PortfolioRes,
  RobotRes,
  SinglePortfolioRes,
  TableAllReq,
  TableAllRes,
  TableOverallRes,
  TableReq,
  YearlyOverallRes,
} from "./tableInterfaces";

export function getTable(data: TableReq): Promise<Array<Order>> {
  return request(UserEndpoints.GetTable, data);
}

export function getAllTable(data: TableAllReq): Promise<OverallChartRes> {
  return request(UserEndpoints.GetAllTable, data);
}

export function getAllAdminTable(data: TableAllReq): Promise<OverallChartRes> {
  return request(UserEndpoints.GetAllAdminTable, data);
}

export function getAllAccTable(data: {
  period?: string;
}): Promise<Array<TableAllRes>> {
  return request(UserEndpoints.GetAllAccsTable, data);
}

export function getPortfolioAllTable(data: {
  id: string;
}): Promise<OverallChartRes> {
  return request(UserEndpoints.GetPortfolioAllTable, data);
}

export function getPortfolioAccTable(data: {
  period?: string;
  id: string;
}): Promise<Array<TableOverallRes>> {
  return request(UserEndpoints.GetPortfolioAccsTable, data);
}

export function deleteAccount(data: {
  account: string;
}): Promise<{ success: boolean }> {
  return request(UserEndpoints.DeleteAcc, data);
}

export function changePercMode(data: {
  mode: boolean;
  id: string;
}): Promise<ChangePercModeResponse> {
  return request(UserEndpoints.changePercMode, data);
}

export function getPortfolios(): Promise<PortfolioRes> {
  return request(UserEndpoints.GetPortfolios);
}

export function getPortfolio(data: {
  id: string;
}): Promise<SinglePortfolioRes> {
  return request(UserEndpoints.GetPortfolio, data);
}

export function createPortfolio(
  data: createPortfolioReq
): Promise<{ success: boolean }> {
  return request(UserEndpoints.CreatePortfolio, data);
}

export function updatePortfolio(data: {
  name?: string;
  id: string;
  accounts: Array<string>;
}): Promise<{ success: boolean }> {
  return request(UserEndpoints.updatePortfolio, data);
}

export function getOverallAccTable(data: {
  period?: string;
}): Promise<Array<TableOverallRes>> {
  return request(UserEndpoints.GetAllAccsTable, data);
}

export function getOverallAccAdminTable(data: {
  period?: string;
}): Promise<Array<TableOverallRes>> {
  return request(UserEndpoints.GetAllAdminAccsTable, data);
}

export function getYearlyAccTable(data: {
  period: "year" | "custom";
  year?: number;
  startYear?: number;
  startMonth?: number;
  endYear?: number;
  endMonth?: number;
}): Promise<Array<YearlyOverallRes>> {
  return request(UserEndpoints.GetAllAccsTable, data);
}

export function getYearlyOverallAccTable(data: {
  period: "year";
  year: number;
}): Promise<Array<YearlyOverallRes>> {
  return request(UserEndpoints.GetOverallAccsTable, data);
}

export function getOverall(): Promise<Order> {
  return request(UserEndpoints.GetOverall);
}

export function getTodayOverall(): Promise<Order> {
  return request(UserEndpoints.GetTodayOverall);
}

export function getSingleOverall(data: {
  account: string;
}): Promise<{ name: string; orders: Order }> {
  return request(UserEndpoints.GetSigleRobotOverall, data);
}

export function getSingle(data: {
  account: string;
  year?: number;
}): Promise<RobotRes> {
  return request(UserEndpoints.GetSingleRobot, data);
}

export function getSingleAll(data: { id: string }): Promise<OverallChartRes> {
  return request(UserEndpoints.getAllSingle, data);
}
