import { useEffect, useState } from "react";
import {
  AccountMarginAndDrawdown,
  GetMarginAndDrawdownRes,
} from "../../network/SumTableApi/SumTableInterfaces";
import { getMarginAndDrawdown } from "../../network/SumTableApi/SumTableApi";
import YearlyDrawdown from "../YearlyDrawdown";
import YearlyMargin from "../YearlyMargin";

interface IProps {
  accounts: string[];
}

const MarginAndDrawdown = (props: IProps) => {
  const [tableData, setTableData] = useState<AccountMarginAndDrawdown[]>([]);

  useEffect(() => {
    getMarginAndDrawdown({ accounts: props.accounts })
      .then((res: GetMarginAndDrawdownRes) => {
        setTableData(res.data);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <p className="font-bold mb-2">Таблица минимальной маржи</p>
      <div className="mb-8">
        <YearlyMargin
          accounts={props.accounts}
          data={tableData}
          selectedYear={2024}
        />
      </div>
      <div className="mb-8">
        <p className="font-bold mb-2">Таблица максимальной просадки</p>
        <YearlyDrawdown
          accounts={props.accounts}
          data={tableData}
          selectedYear={2024}
        />
      </div>
    </div>
  );
};

export default MarginAndDrawdown;
