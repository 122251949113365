import {
  Button,
  Modal,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState, useEffect } from "react";
import { updatePortfolio } from "../../network/TableApi/tableApi";
import { UserAccount } from "../../types/component";
import PortfolioCheckbox from "./PortfolioCheckbox";
import { EditPortfolioRes } from "../../network/TableApi/tableInterfaces";
import { deletePortfolio } from "../../network/PortfolioApi/portfolioApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

interface IProps {
  loaded: Array<EditPortfolioRes>;
  name: string;
  accounts: Array<UserAccount>;
  id: string;
  onCreate: (value: boolean) => void;
}

const EditPortfolioModal = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>(props.name);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Обновляем имя при изменении props.name
  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  // Обновляем выбранные аккаунты при изменении props.loaded
  useEffect(() => {
    const accs = props.loaded.map((account) => account.account.account);
    setSelected(accs);
  }, [props.loaded]);

  function checkCondition(): boolean {
    if (name.length === 0) {
      return false;
    }
    if (selected.length === 0) {
      return false;
    }
    return true;
  }

  function handleSave() {
    updatePortfolio({ accounts: selected, id: props.id, name: name }).then(
      (r) => {
        if (r.success) {
          handleClose();
          props.onCreate(true);
        }
      }
    );
  }

  function handleDelete() {
    deletePortfolio({ portfolio: props.id }).then((r) => {
      if (r.success) {
        setConfirmOpen(false);
        handleClose();
        props.onCreate(true);
      }
    });
  }

  const renderedAccounts = props.accounts.map((account) => (
    <PortfolioCheckbox
      key={account.account}
      name={account.name || account.account}
      checked={selected.includes(account.account)}
      onChange={(e) => {
        if (e) {
          setSelected([...selected, account.account]);
        } else {
          setSelected(selected.filter((item) => item !== account.account));
        }
      }}
    />
  ));

  return (
    <div>
      <Button onClick={handleOpen}>Редактировать портфель</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-portfolio-modal-title"
        aria-describedby="edit-portfolio-modal-description"
      >
        <Box sx={style}>
          <TextField
            label="Название портфеля"
            variant="standard"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {renderedAccounts}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              disabled={!checkCondition()}
              onClick={handleSave}
            >
              Сохранить
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setConfirmOpen(true)}
            >
              Удалить
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Вы уверены, что хотите удалить этот портфель?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Это действие необратимо. Все данные, связанные с этим портфелем,
            будут удалены.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Отмена</Button>
          <Button onClick={handleDelete} color="error">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditPortfolioModal;
