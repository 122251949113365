import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import { getAllTable } from "../network/TableApi/tableApi";

interface IProps {
  startDate?: string;
  endDate?: string;
}

const PeriodTable = (props: IProps) => {
  const [all, setAll] = useState<any>([]);
  const [money, setMoney] = useState<boolean>(false);

  useEffect(() => {
    const params: any = {};
    if (props.startDate) params.startDate = props.startDate;
    if (props.endDate) params.endDate = props.endDate;

    getAllTable(params).then((data) => {
      setAll(data);
    });
  }, [props]);

  const getColorStyle = (value: number) => {
    return value >= 0 ? { color: "#3ac47d" } : { color: "#d92550" };
  };

  const renderedRows = () => {
    if (!all.data || all.data.overall.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            Нет данных для отображения
          </TableCell>
        </TableRow>
      );
    }

    const rev = [...all.data.overall].reverse();
    const revM = [...all.data.month].reverse();
    const revD = [...all.data.today].reverse();

    return (
      <TableRow
        key={all.data.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <b>Период</b>
        </TableCell>
        <TableCell>
          {all.data.overall[0]?.CloseTime.split("T")[0] || "N/A"}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(rev[0]?.factInc ?? 0)}>
          {money
            ? rev[0]?.amountMins
              ? `$${rev[0].amountMins.toFixed(2)}`
              : "$0.00"
            : rev[0]?.factInc
            ? `${rev[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell
          align="right"
          sx={getColorStyle(rev[0]?.amountCompound ?? 0)}
        >
          {money
            ? rev[0]?.amountMins
              ? `$${rev[0].amountMins.toFixed(2)}`
              : "$0.00"
            : rev[0]?.amountCompound
            ? (rev[0].amountCompound * 100).toFixed(2) + "%"
            : "0%"}
        </TableCell>
        <TableCell align="right">
          {money
            ? revM[0]?.amountMins
              ? `$${(revM[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revM[0]?.factInc
            ? `${revM[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell align="right">
          {money
            ? revD[0]?.amountMins
              ? `$${(revD[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revD[0]?.factInc
            ? `${revD[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div className="flex">
        <FormControlLabel
          value="start"
          checked={money}
          onChange={() => {
            setMoney(!money);
          }}
          control={<Switch color="primary" />}
          label="Показывать в деньгах: "
          labelPlacement="start"
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              <TableCell>
                <b>Дата старта</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Абсолютный доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за месяц</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за день</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedRows()}
            {all.data && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <b>Итого</b>
                </TableCell>
                <TableCell>~</TableCell>
                <TableCell
                  align="right"
                  sx={getColorStyle(
                    all.data.overall[all.data.overall.length - 1]?.factInc ?? 0
                  )}
                >
                  {money
                    ? all.data.overall[all.data.overall.length - 1]?.amountMins
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall[all.data.overall.length - 1]?.factInc
                    ? `${all.data.overall[
                        all.data.overall.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={getColorStyle(
                    all.data.overall[all.data.overall.length - 1]
                      ?.amountCompound ?? 0
                  )}
                >
                  {money
                    ? all.data.overall[all.data.overall.length - 1]?.amountMins
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall[all.data.overall.length - 1]
                        ?.amountCompound
                    ? `${(
                        all.data.overall[all.data.overall.length - 1]
                          .amountCompound * 100
                      ).toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.month[all.data.month.length - 1]?.amountMins
                      ? `$${(
                          all.data.month[all.data.month.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.month[all.data.month.length - 1]?.factInc
                    ? `${all.data.month[
                        all.data.month.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.today[all.data.today.length - 1]?.amountMins
                      ? `$${(
                          all.data.today[all.data.today.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.today[all.data.today.length - 1]?.factInc
                    ? `${all.data.today[
                        all.data.today.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PeriodTable;
