import { useMemo } from "react";
import { Table } from "antd";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

interface AccountDetail {
  month: number;
  year: number;
  margin?: number;
}

interface AccountMarginAndDrawdown {
  account: string;
  name?: string;
  details: AccountDetail[];
}

interface Props {
  data: AccountMarginAndDrawdown[];
  selectedYear: number;
  accounts?: Array<string>;
}

const getMarginCellColor = (margin: number) => {
  if (margin === 0) return "#808080";
  if (margin > 1000) return "green";
  if (margin >= 500 && margin <= 999) return "orange";
  return "red";
};

const YearlyMargin = ({ data, selectedYear, accounts }: Props) => {
  const activeMonths = useMemo(() => {
    const monthsSet = new Set<number>();
    data.forEach((row) => {
      row.details.forEach((detail) => {
        if (detail.year === selectedYear && detail.margin !== 0) {
          monthsSet.add(detail.month);
        }
      });
    });
    return Array.from(monthsSet).sort((a, b) => a - b);
  }, [data, selectedYear]);
  console.log(accounts);
  // Названия месяцев сокращаем до трёх букв
  const monthNames = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ];

  const columns = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      fixed: "left" as const,
      width: 150, // Ограничиваем ширину колонки
      render: (_: any, record: any) => (
        <Link
          className="text-blue-500 underline"
          to={createPath({
            route: AppRoutes.RobotPage,
            args: [{ key: "id", value: record.account }],
          })}
        >
          <b>
            {record.name
              ? `${record.name} ${record.account.slice(-4)}`
              : record.account}
          </b>
        </Link>
      ),
    },
    ...activeMonths.map((month) => ({
      title: `${monthNames[month - 1]} (марж)`,
      dataIndex: `month_${month}`,
      key: `month_${month}`,
      align: "right" as const,
      width: 80, // Устанавливаем фиксированную ширину колонки
      render: (value: number | null) => (
        <span
          style={{
            color: getMarginCellColor(value || 0),
            fontWeight: "normal",
            whiteSpace: "nowrap",
            minWidth: 50,
            display: "inline-block",
          }}
        >
          {value !== null ? `${value}` : "—"}
        </span>
      ),
    })),
  ];

  const dataSource = data.map((row) => {
    const dataItem: any = {
      key: row.account,
      name: row.name,
      account: row.account,
    };
    activeMonths.forEach((month) => {
      const monthlyDetails = row.details.filter(
        (detail) => detail.month === month && detail.year === selectedYear
      );
      if (monthlyDetails.length === 0) {
        dataItem[`month_${month}`] = null;
      } else {
        const minMargin = Math.min(
          ...monthlyDetails.map((detail) => detail.margin ?? 0)
        );
        dataItem[`month_${month}`] = isNaN(minMargin) ? null : minMargin;
      }
    });
    return dataItem;
  });

  return (
    <div style={{ maxWidth: "900px" }}>
      {" "}
      {/* Ограничиваем ширину */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="key"
        scroll={{ x: true }}
        size="small" // Компактный стиль таблицы
      />
    </div>
  );
};

export default YearlyMargin;
