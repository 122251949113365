import { Link, useLocation } from "react-router-dom";
import AppRoutes from "../structure/routes";
import { NavLink } from "../types/component";
import { useAuth } from "../providers/AuthProvider";
import { useEffect } from "react";
import { getHeader, logoutUser } from "../network/UserApi/userApi";

interface IProps {
  navLinks: Array<NavLink>;
  external?: boolean;
}

const NavBar = (props: IProps) => {
  const [userInfo, setUserInfo] = useAuth();

  useEffect(() => {
    getHeader().then((r) => {
      setUserInfo(r);
    });
  }, []);

  const usePathName = () => {
    const location = useLocation();
    return location.pathname;
  };

  const CheckPath = (arg: string) => {
    return usePathName() === arg;
  };

  const renderedLinks = props.navLinks.map((link: NavLink) => {
    return (
      <Link to={link.appRoute} key={link.appRoute}>
        <div
          className={`${CheckPath(link.appRoute) ? "navActive" : "navMain"}`}
        >
          <p className="block ml-2">{link.linkName}</p>
        </div>
      </Link>
    );
  });

  return (
    <nav className="sticky top-0 w-[120px] pt-4 pb-4">
      {renderedLinks}
      {userInfo.isAdmin && !props.external && (
        <Link to={AppRoutes.Admin}>
          <div
            className={`${
              CheckPath(AppRoutes.Admin) ? "navActive" : "navMain"
            }`}
          >
            <p className="block ml-2">Админка</p>
          </div>
        </Link>
      )}
      {!props.external && (
        <Link to={AppRoutes.Main}>
          <div
            className="navLogout"
            onClick={() => {
              setUserInfo({});
              logoutUser();
            }}
          >
            <p className="block ml-2">Выход</p>
          </div>
        </Link>
      )}
    </nav>
  );
};

export default NavBar;
