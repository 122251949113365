import { useEffect, useState } from "react";
import { getMarginAndDrawdown } from "../network/SumTableApi/SumTableApi";
import YearlyDrawdown from "../components/YearlyDrawdown";
import YearlyMargin from "../components/YearlyMargin";
import { Select, Form } from "antd";

interface AccountDetail {
  month: number;
  year: number;
  drawdown?: number;
  margin?: number;
}

interface AccountMarginAndDrawdown {
  account: string;
  name?: string;
  details: AccountDetail[];
}

const DrawdownPage = () => {
  const [data, setData] = useState<AccountMarginAndDrawdown[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setLoading(true);
    getMarginAndDrawdown()
      .then((res) => {
        if (res.success) {
          setData(res.data);
        } else {
          setError("Ошибка загрузки данных");
        }
        setLoading(false);
      })
      .catch(() => {
        setError("Ошибка загрузки данных");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>{error}</div>;

  const handleYearChange = (value: number) => {
    setSelectedYear(value);
  };

  return (
    <div className="defaultLayout flex flex-col gap-10">
      <Form layout="inline" style={{ marginBottom: 16 }}>
        <Form.Item label="Год">
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>

      <YearlyMargin data={data} selectedYear={selectedYear} />
      <YearlyDrawdown data={data} selectedYear={selectedYear} />
    </div>
  );
};

export default DrawdownPage;
