import { Link, useNavigate } from "react-router-dom";
import Button from "../components/widgets/Button";
import Input from "../components/widgets/Input";
import AppRoutes from "../structure/routes";
import { useEffect, useState } from "react";
import { loginUser } from "../network/UserApi/userApi";
import { useAuth } from "../providers/AuthProvider";

interface IProps {}

const LoginPage = (props: IProps) => {
  const [userInfo, setUserInfo] = useAuth();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (mail: string, password: string) => {
    loginUser({ email: mail, password: password })
      .then((r) => {
        if (r.status === "success") {
          setUserInfo(r);
          navigate(AppRoutes.UserCabinet);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Обработчик нажатия клавиши Enter
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin(mail, password);
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen w-full flex-col"
      onKeyDown={handleKeyPress} // Добавляем обработчик события
    >
      <Input
        variant="time"
        label="Электронная почта"
        onInput={setMail}
        className="mb-6"
      />
      <Input
        variant="time"
        label="Пароль"
        type="password"
        onInput={setPassword}
      />
      <p
        className={`text-red-700 mb-2 transition ${
          error ? "ease-out" : "ease-out invisible"
        } `}
      >
        Неправильный логин или пароль
      </p>
      <Button
        className={`${error ? " bg-red-700" : "bg-black"}`}
        onClick={() => {
          handleLogin(mail, password);
        }}
      >
        Войти
      </Button>
      <div className="flex flex-row">
        <p>
          Еще нет аккаунта?
          <Link to={AppRoutes.RegisterPage}>
            <p className="font-bold">Зарегистрируйтесь</p>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
