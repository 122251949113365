import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import { NavLink } from "../types/component";
import AppRoutes from "../structure/routes";
import Header from "./Header";

interface IProps {}
const navLinks: Array<NavLink> = [
  { linkName: "Сегодня", appRoute: AppRoutes.TodayPage },
  { linkName: "Мой портфель", appRoute: AppRoutes.UserCabinet },
  { linkName: "Настройки", appRoute: AppRoutes.UserPage },
  { linkName: "Импорт", appRoute: AppRoutes.ImportPage },
  { linkName: "Доходы", appRoute: AppRoutes.IncomePage },
  { linkName: "Просадки", appRoute: AppRoutes.DrawdownPage },
  { linkName: "Портфели", appRoute: AppRoutes.MyPortfolio },
];
const Layout = (props: IProps) => {
  return (
    <div className="flex flex-col items-center">
      <Header />
      <div className="flex items-center">
        <div className="relative p-6 flex gap-6">
          <div>
            <NavBar navLinks={navLinks} />
          </div>
          <div className="w-[1100px] pb-[80px] ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
