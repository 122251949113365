import PortfolioEndpoints from "../../structure/portfolioEndpoints";
import { request } from "../mainRequest";
import {
  DeletePortfolioReq,
  DeletePortfolioRes,
  GetPortfolioTableReq,
  GetPortfolioTableRes,
} from "./portfolioInterfaces";

export function deletePortfolio(
  data: DeletePortfolioReq
): Promise<DeletePortfolioRes> {
  return request(PortfolioEndpoints.DeletePortfolio, data);
}

export function getPortfolioTable(
  data: GetPortfolioTableReq
): Promise<GetPortfolioTableRes> {
  return request(PortfolioEndpoints.GetPortfolioTable, data);
}
