import OverallTable from "../components/OverallTable";
import ChartOverall from "../components/ChartOverall";
import { useState } from "react";
import PeriodTable from "../components/PeriodTable";

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const UserCabinet = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(formatDate(new Date()));
  return (
    <div className="defaultLayout">
      <div className="font-bold mb-4">
        <p>Мой портфель</p>
      </div>

      <div>
        <OverallTable />
        <div className="m-4">
          <label>
            Начало периода:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            Конец периода:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <button
            className="defaultButton ml-4"
            onClick={() => {
              setStartDate("");
            }}
          >
            Сбросить
          </button>
        </div>
        {startDate && endDate && (
          <PeriodTable startDate={startDate} endDate={endDate} />
        )}
        <div className="flex flex-col mt-10">
          <p className="mb-4">Графики за все время</p>
          <ChartOverall
            startDate={startDate}
            endDate={endDate}
            type={"amountCompound"}
            desc="Сложный процент"
          />
          <ChartOverall
            startDate={startDate}
            endDate={endDate}
            type={"factInc"}
            desc="Фактический процент"
          />
          <ChartOverall
            startDate={startDate}
            endDate={endDate}
            type={"amountMins"}
            desc="Заработано"
          />
          <ChartOverall
            startDate={startDate}
            endDate={endDate}
            type={"balanceBefore"}
            desc="Баланс"
          />
        </div>
      </div>
    </div>
  );
};
