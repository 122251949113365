import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  LabelList, // Импортируем LabelList
} from "recharts";
import { YearlyOrder } from "../network/TableApi/tableInterfaces";

interface IProps {
  orders: Array<YearlyOrder>;
}

// Кастомный компонент для Tooltip
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-2 border border-gray-300">
        <p className="label">{`${payload[0].name} : ${payload[0].value}%`}</p>
      </div>
    );
  }

  return null;
};

const RobotChart = (props: IProps) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const chartData = props.orders.map((yearlyOrder) => {
    const lastOrder = yearlyOrder.orders[yearlyOrder.orders.length - 1];
    return {
      month: monthNames[yearlyOrder.month - 1],
      factInc: parseFloat(lastOrder.factInc.toFixed(2)),
      barColor: lastOrder.factInc >= 0 ? "#3ac47d" : "#d92550",
    };
  });

  const maxFactInc = Math.max(...chartData.map((data) => data.factInc));

  return (
    <div>
      <p>
        <b>График доходов по месяцам</b>
      </p>
      <BarChart
        width={950}
        height={500}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis domain={[-110, maxFactInc + 10]} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="factInc" name="Доход">
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.barColor} />
          ))}
          <LabelList
            dataKey="factInc"
            formatter={(value: any) => `${value}%`}
            position="top"
            style={{ fontSize: "14px", fill: "#000" }}
          />
        </Bar>
      </BarChart>
    </div>
  );
};

export default RobotChart;
