enum UserEndpoints {
  GetHeader = "/api/auth/header",
  UserLogout = "/api/auth/logout",
  UserRegister = "/api/auth/register",
  UserLogin = "/api/auth/login",
  GetTable = "/api/table/get",
  GetAllTable = "/api/table/getAll",
  GetPortfolioAllTable = "/api/portfolio/getPortfolioAll",
  GetAllAccsTable = "/api/table/getAllAccs",
  GetOverallAccsTable = "/api/table/getOverallAccs",
  GetPortfolios = "/api/portfolio/getPortfolios",
  GetPortfolio = "/api/portfolio/getPortfolio",
  changePercMode = "/api/portfolio/changePercMode",
  CreatePortfolio = "/api/portfolio/createPortfolio",
  updatePortfolio = "/api/portfolio/updatePortfolio",
  GetPortfolioAccsTable = "/api/portfolio/getPortfolioAccs",
  GetOverall = "/api/table/getOverallStats",
  RenameAcc = "/api/user/changeName",
  GetSigleRobotOverall = "/api/table/getOverallSingleStats",
  GetSingleRobot = "/api/table/getSingle",
  GetTodayOverall = "/api/table/getTodayStats",
  GetAllAdminAccsTable = "/api/admin/getAllAccs",
  GetAllAdminTable = "/api/admin/getAll",
  getAllSingle = "/api/table/getAllSingle",
  DeleteAcc = "/api/user/deleteAccount",
}

export default UserEndpoints;
