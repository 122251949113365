import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Order, RobotRes } from "../network/TableApi/tableInterfaces";
import { getSingle } from "../network/TableApi/tableApi";
import RobotChart from "../components/RobotChart";
import MonthlyDrawdownChart from "../components/MonthlyDrawdownChart";
import MonthlyMarginChart from "../components/MonthlyMarginChart";
import SingleChart from "../components/Single/SingleChart";
import Spinner from "../components/Spinner";
import { RobotInfo } from "../network/RobotApi/robotInterfaces";
import {
  changeRobotPercentMode,
  getRobotInfo,
} from "../network/RobotApi/robotApi";
import { message, Switch } from "antd";
import YearlyIncome from "../components/YearlyIncome";
import YearlyMargin from "../components/YearlyMargin";
import YearlyDrawdown from "../components/YearlyDrawdown";
import {
  AccountMarginAndDrawdown,
  GetMarginAndDrawdownRes,
} from "../network/SumTableApi/SumTableInterfaces";
import { getMarginAndDrawdown } from "../network/SumTableApi/SumTableApi";

interface IProps {}

const PublicRobotPage = (props: IProps) => {
  const [overall, setOverall] = useState<RobotInfo>();
  const [robot, setRobot] = useState<RobotRes>();
  const [tableData, setTableData] = useState<AccountMarginAndDrawdown[]>([]);

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    new Date().getMonth() + 1
  );
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      setLoading(true);
      getRobotInfo({ account: id }).then((data) => setOverall(data.data));
      getSingle({ account: id, year: selectedYear })
        .then((data) => setRobot(data))
        .finally(() => setLoading(false));
    }
    if (id)
      getMarginAndDrawdown({ accounts: [id] })
        .then((res: GetMarginAndDrawdownRes) => {
          setTableData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
  }, [id, selectedYear]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  const getLastOrder = (orders: Order[] | undefined): Order | undefined => {
    if (!orders || orders.length === 0) {
      return undefined;
    }
    const sortedOrders = orders
      .slice()
      .sort(
        (a, b) =>
          new Date(b.CloseTime).getTime() - new Date(a.CloseTime).getTime()
      );
    return sortedOrders[0];
  };

  const formatIncome = (value: number | undefined, overall?: boolean) => {
    if (value === undefined) return "Нет данных";
    const formattedValue = overall
      ? (value * 100).toFixed(2)
      : value.toFixed(2);
    const colorClass = value >= 0 ? "text-green-500" : "text-red-500";
    return <span className={`font-bold ${colorClass}`}>{formattedValue}%</span>;
  };

  const formatIncomeMoney = (value: number | undefined, overall?: boolean) => {
    if (value === undefined) return "Нет данных";
    const formattedValue = (overall ? value / 1 : value / 100).toFixed(2);
    const colorClass = value >= 0 ? "text-green-500" : "text-red-500";
    return <span className={`font-bold ${colorClass}`}>${formattedValue}</span>;
  };

  let formattedData;
  if (robot) {
    formattedData = {
      today: getLastOrder(robot.data.today),
      yesterday: getLastOrder(robot.data.yesterday),
      currentWeek: getLastOrder(robot.data.currentWeek),
      lastWeek: getLastOrder(robot.data.lastWeek),
      currentMonth: getLastOrder(robot.data.currentMonth),
      lastMonth: getLastOrder(robot.data.lastMonth),
    };
  }

  return (
    <div className="defaultLayout">
      {formattedData && (
        <div>
          <div>
            <div className="mb-6">
              <h1>
                Информация о роботе:{" "}
                <b>{overall?.name ? `${overall.name} ${id?.slice(-4)}` : id}</b>
              </h1>
              {!overall?.percentMode && (
                <p>
                  Баланс:{" "}
                  <b>
                    $
                    {overall?.balance
                      ? (overall.balance / 100).toFixed(2)
                      : "0.00"}
                  </b>
                </p>
              )}
            </div>
            <p>
              <b>Доходы по периодам:</b>
            </p>
            <p>
              Фактический доход за все время:{" "}
              {formatIncome(overall?.totalFactIncomePercent)}
            </p>
            <p>
              Абсолютный доход за все время:{" "}
              {formatIncome(overall?.totalAbsoluteIncomePercent) || 0}
            </p>
            {!overall?.percentMode && (
              <p>
                Доход за все время:{" "}
                {formatIncomeMoney(overall?.totalIncomeMoney)}
              </p>
            )}

            <div className="flex gap-4">
              <div>
                <p>
                  Доход за сегодня:
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeToday.money)}
                  {!overall?.percentMode && " /"}{" "}
                  {formatIncome(overall?.incomeToday.percent)}
                </p>
                <p>
                  Доход за текущую неделю:{" "}
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeThisWeek.money)}
                  {!overall?.percentMode && " /"}{" "}
                  {formatIncome(overall?.incomeThisWeek.percent)}
                </p>
                <p>
                  Доход за текущий месяц:{" "}
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeCurrentMonth.money)}
                  {!overall?.percentMode && " /"}{" "}
                  {formatIncome(overall?.incomeCurrentMonth.percent)}
                </p>
              </div>
              <div>
                <p>
                  Доход за вчера:{" "}
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeYesterday.money)}
                  {!overall?.percentMode && " /"}
                  {formatIncome(overall?.incomeYesterday.percent)}
                </p>
                <p>
                  Доход за прошлую неделю:{" "}
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeLastWeek.money)}
                  {!overall?.percentMode && " /"}{" "}
                  {formatIncome(overall?.incomeLastWeek.percent)}
                </p>
                <p>
                  Доход за прошлый месяц:{" "}
                  {!overall?.percentMode &&
                    formatIncomeMoney(overall?.incomeLastMonth.money)}
                  {!overall?.percentMode && "/"}{" "}
                  {formatIncome(overall?.incomeLastMonth.percent)}
                </p>
              </div>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="mt-4">
                <SingleChart
                  type={"amountCompound"}
                  desc="Сложный процент"
                  account={id || ""}
                />
              </div>
              <div className="mt-4">
                <SingleChart
                  type={"factInc"}
                  desc="Фактический процент"
                  account={id || ""}
                />
              </div>
              {!overall?.percentMode && (
                <div className="mt-4">
                  <SingleChart
                    type={"amountMins"}
                    desc="Заработано"
                    account={id || ""}
                  />
                </div>
              )}

              {robot?.data.year && (
                <div>
                  <div className="mt-4">
                    <label htmlFor="year-select">Выберите год:</label>
                    <select
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {Array.from({ length: 10 }, (_, i) => {
                        const year = new Date().getFullYear() - i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                    <RobotChart orders={robot?.data.year} />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="month-select">Выберите месяц:</label>
                    <select
                      id="month-select"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {robot?.data.yearlyDetails.map((detail: any) => (
                        <option key={detail.month} value={detail.month}>
                          {new Date(0, detail.month - 1).toLocaleString(
                            "ru-RU",
                            {
                              month: "long",
                            }
                          )}
                        </option>
                      ))}
                    </select>
                    {selectedMonth !== undefined && (
                      <>
                        <MonthlyDrawdownChart
                          details={
                            robot?.data.yearlyDetails.find(
                              (detail) => detail.month === selectedMonth
                            )?.details || []
                          }
                        />
                        <div className="mt-4">
                          <MonthlyMarginChart
                            details={
                              robot?.data.yearlyDetails.find(
                                (detail) => detail.month === selectedMonth
                              )?.details || []
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {id && (
                    <div>
                      <div className="mb-8">
                        <p className="font-bold mb-2">Таблица доходов</p>
                        <YearlyIncome accounts={[id]} />
                      </div>
                      <div className="mb-8">
                        <p className="font-bold mb-2">
                          Таблица минимальной маржи
                        </p>
                        <YearlyMargin
                          accounts={[id]}
                          data={tableData}
                          selectedYear={2024}
                        />
                      </div>
                      <div className="mb-8">
                        <p className="font-bold mb-2">
                          Таблица максимальной просадки
                        </p>
                        <YearlyDrawdown
                          accounts={[id]}
                          data={tableData}
                          selectedYear={2024}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PublicRobotPage;
