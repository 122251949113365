import { useMemo } from "react";
import { Table } from "antd";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { Link } from "react-router-dom";

interface AccountDetail {
  month: number;
  year: number;
  drawdown?: number;
}

interface AccountMarginAndDrawdown {
  account: string;
  name?: string;
  details: AccountDetail[];
}

interface Props {
  data: AccountMarginAndDrawdown[];
  selectedYear: number;
  accounts?: Array<string>;
}

const getDrawdownCellColor = (drawdown: number) => {
  if (drawdown === 0) return "#808080";
  if (drawdown > -20) return "green";
  if (drawdown >= -30) return "orange";
  return "red";
};

const YearlyDrawdown = ({ data, selectedYear, accounts }: Props) => {
  const activeMonths = useMemo(() => {
    const monthsSet = new Set<number>();
    data.forEach((row) => {
      row.details.forEach((detail) => {
        if (detail.year === selectedYear && detail.drawdown !== 0) {
          monthsSet.add(detail.month);
        }
      });
    });
    return Array.from(monthsSet).sort((a, b) => a - b);
  }, [data, selectedYear]);

  const getMinDetailDrawdown = (details: AccountDetail[]) => {
    return details.length
      ? Math.min(...details.map((detail) => detail.drawdown ?? 0))
      : 0;
  };

  // Сокращаем названия месяцев до 3 символов
  const monthNames = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ];

  const columns = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      fixed: "left" as const,
      width: 150, // Устанавливаем фиксированную ширину колонки
      render: (_: any, record: any) => (
        <Link
          className="text-blue-500 underline"
          to={createPath({
            route: AppRoutes.RobotPage,
            args: [{ key: "id", value: record.account }],
          })}
        >
          <b>
            {record.name
              ? `${record.name} ${record.account.slice(-4)}`
              : record.account}
          </b>
        </Link>
      ),
    },
    ...activeMonths.map((month) => ({
      title: `${monthNames[month - 1]} (прос)`, // Процент в заголовке
      dataIndex: `month_${month}`,
      key: `month_${month}`,
      align: "right" as const,
      width: 80, // Ограничиваем ширину столбцов
      render: (value: number) => (
        <span
          style={{
            color: getDrawdownCellColor(value),
            fontWeight: "normal",
            whiteSpace: "nowrap",
            minWidth: 50,
            display: "inline-block",
          }}
        >
          {value}
        </span>
      ),
    })),
  ];

  const dataSource = data.map((row) => {
    const dataItem: any = {
      key: row.account,
      name: row.name,
      account: row.account,
    };
    activeMonths.forEach((month) => {
      const monthlyDetails = row.details.filter(
        (detail) => detail.month === month && detail.year === selectedYear
      );
      const minDrawdown = getMinDetailDrawdown(monthlyDetails);
      dataItem[`month_${month}`] = minDrawdown;
    });
    return dataItem;
  });

  return (
    <div style={{ maxWidth: "900px" }}>
      {" "}
      {/* Ограничиваем ширину таблицы */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="key"
        scroll={{ x: true }}
        size="small" // Компактный стиль таблицы
      />
    </div>
  );
};

export default YearlyDrawdown;
