import { AC } from "../../utils/style";
import { StringObject } from "../../types";
import { IButtonStyle } from "../../types/component";
import { useCallback, useMemo } from "react";

export type ButtonType = "main" | "gay" | "radio" | "outlineRadio";

export interface ButtonProps {
  variant?: ButtonType;
  children: string;
  disabled?: boolean;
  className?: string;

  onClick?: () => void;
}

const BASE_BUTTON_STYLE =
  "flex justify-center items-center px-5 py-2 cursor-pointer select-none";

const DEFAULT_VARIANT: ButtonType = "main";

const BUTTON_STYLES: StringObject<IButtonStyle> = {
  main: {
    main: "py-3 px-6 rounded-full bg-main text-white font-semibold text-xl",
    disabled: "bg-text-gray",
  },
  gay: {
    main: "py-3 px-6 rounded-full bg-rainbow text-white font-semibold text-xl",
    disabled: "bg-text-gray",
  },
  radio: {
    main: "py-4 px-6 rounded-lg border-2 border-main-secondary bg-main-secondary text-base text-white",
    disabled: "bg-transparent",
  },
  outlineRadio: {
    main: "py-4 px-6 rounded-lg border-2 border-main-secondary bg-transparent text-base text-white",
    disabled: "",
  },
};
// className="cursor-pointer"

const Button = (props: ButtonProps) => {
  const style = useMemo(
    () =>
      AC(
        BUTTON_STYLES[props.variant || DEFAULT_VARIANT].main,
        props.disabled
          ? BUTTON_STYLES[props.variant || DEFAULT_VARIANT].disabled
          : ""
      ),
    [props]
  );
  const onClick = useCallback(() => {
    props.onClick && !props.disabled && props.onClick();
  }, [props]);

  return (
    <div
      className={AC(BASE_BUTTON_STYLE, style, props.className)}
      onClick={onClick}
    >
      <p>{props.children}</p>
    </div>
  );
};

export default Button;
