import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { UserAccount } from "../../types/component";

interface IProps {
  accounts: Array<UserAccount>;
  onChange: (value: string) => void;
}

const ImportSelector = (props: IProps) => {
  const [account, setAccount] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value);
    setAccount(event.target.value);
  };

  const renderedMenu = props.accounts.map((el) => {
    return <MenuItem value={el.account}>{el.account}</MenuItem>;
  });

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Аккаунт</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={account}
          onChange={handleChange}
          autoWidth
          label="Age"
        >
          {renderedMenu}
        </Select>
      </FormControl>
    </div>
  );
};

export default ImportSelector;
