import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
} from "recharts";
import { Detail } from "../network/TableApi/tableInterfaces";

interface IProps {
  details: Detail[];
}

const MonthlyDrawdownChart: React.FC<IProps> = ({ details }) => {
  if (!details) {
    console.error("No details provided to MonthlyDrawdownChart");
    return null; // Возвращаем null или какой-то заполнитель, если данные не доступны
  }

  // Функция для фильтрации деталей по минимальной просадке за день
  const filterDetailsByMinDrawdown = (details: Detail[]): Detail[] => {
    const detailsByDay: { [day: string]: Detail } = {};

    // Перебираем детали и находим деталь с минимальной просадкой за каждый день
    details.forEach((detail) => {
      const day = new Date(detail.createdAt).toISOString().substring(0, 10); // Преобразуем createdAt в дату и получаем только день
      if (!detailsByDay[day] || detail.drawdown < detailsByDay[day].drawdown) {
        detailsByDay[day] = detail;
      }
    });

    // Преобразуем объект обратно в массив деталей
    return Object.values(detailsByDay);
  };

  // Фильтруем детали по минимальной просадке за день
  const filteredDetails = filterDetailsByMinDrawdown(details);

  // Подготовка данных для графика и сортировка дней в правильном порядке
  const chartData = filteredDetails
    .map((detail) => ({
      date: new Date(detail.createdAt).toISOString().substring(0, 10), // Используем дату как ось X
      drawdown: detail.drawdown, // Здесь drawdown может быть отрицательным числом
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Находим максимальное абсолютное значение просадки
  const maxAbsDrawdown = Math.max(
    Math.abs(Math.min(...filteredDetails.map((detail) => detail.drawdown))), // минимальная просадка
    Math.abs(Math.max(...filteredDetails.map((detail) => detail.drawdown))) // максимальная просадка
  );

  // Функция для получения цвета столбца в зависимости от значения просадки
  const getDrawdownColor = (drawdown: number) => {
    if (drawdown < -30) return "#d92550"; // красный
    if (drawdown <= -20) return "#f7b924"; // желтый
    return "#3ac47d"; // зеленый
  };

  return (
    <div>
      <p>
        <b>График просадок по дням за месяц</b>
      </p>
      <BarChart
        width={950}
        height={400}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={[-maxAbsDrawdown - 5, maxAbsDrawdown + 5]} />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="drawdown" name="Просадка">
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={getDrawdownColor(entry.drawdown)}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default MonthlyDrawdownChart;
