import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getSingleAll } from "../../network/TableApi/tableApi";

// Регистрация модулей ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  type: "factInc" | "amountMins" | "amountCompound";
  account: string;
  desc: string;
  startDate?: string;
  endDate?: string;
}

const SingleChart = (props: IProps) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    // Подготовка параметров запроса
    const params: any = {};
    if (props.startDate) params.startDate = props.startDate;
    if (props.endDate) params.endDate = props.endDate;

    getSingleAll({ id: props.account }).then((data) => {
      if (!data || !data.data || !data.data.overall) {
        console.error("Неверная структура данных", data);
        return;
      }

      // Подготовка данных для графика
      const labels = data.data.overall.map(
        (item: any) => item.CloseTime.split("T")[0]
      );

      const dataset = data.data.overall.map((item: any) => {
        let value = item[props.type];
        if (props.type === "amountCompound") {
          value = value * 100;
        }
        return value;
      });

      // Установка данных для графика
      setChartData({
        labels,
        datasets: [
          {
            label: props.desc,
            data: dataset,
            borderColor: "rgb(75, 192, 192)",
            segment: {
              borderColor: (ctx: any) => {
                const { p0, p1 } = ctx;
                return p1.parsed.y < p0.parsed.y
                  ? "rgb(192,75,75)" // Красный, если падает
                  : "rgb(75,192,75)"; // Зеленый, если растет
              },
              borderDash: (ctx: any) => {
                const { p0, p1 } = ctx;
                return isNaN(p1.parsed.y) || isNaN(p0.parsed.y)
                  ? [6, 6]
                  : undefined; // Пунктирная линия, если значение NaN
              },
            },
            spanGaps: true,
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
            tension: 0.1, // Для плавных линий
          },
        ],
      });
    });
  }, [props, props.type]);

  if (!chartData) {
    return <p>Загрузка данных...</p>;
  }

  return (
    <div className="flex justify-center flex-col">
      <p>{props.desc}</p>
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "nearest",
              intersect: false,
              callbacks: {
                label: (context: any) => {
                  const value = context.raw;
                  switch (props.type) {
                    case "factInc":
                    case "amountCompound":
                      return `${value.toFixed(2)}%`;
                    case "amountMins":
                      return `$${value.toFixed(2)}`;
                    default:
                      return value;
                  }
                },
              },
            },
          },
          scales: {
            x: {
              type: "category",
              labels: chartData.labels,
            },
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value: any) => {
                  if (
                    props.type === "amountCompound" ||
                    props.type === "factInc"
                  ) {
                    return `${value}%`;
                  }
                  return value;
                },
              },
            },
          },
          interaction: {
            mode: "nearest",
            intersect: false,
          },
        }}
      />
    </div>
  );
};

export default SingleChart;
