import { useEffect, useState } from "react";
import { TableOverallRes } from "../../network/TableApi/tableInterfaces";
import {
  getAllAdminTable,
  getOverallAccAdminTable,
} from "../../network/TableApi/tableApi";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";

interface IProps {}

const AdminOverall = (props: IProps) => {
  const [tableData, setTableData] = useState<Array<TableOverallRes>>();
  const [all, setAll] = useState<any>([]);
  const [money, setMoney] = useState<boolean>(false);

  useEffect(() => {
    getOverallAccAdminTable({}).then((res) => {
      setTableData(res);
    });
    getAllAdminTable({}).then((data) => {
      setAll(data);
    });
  }, []);

  const renderedRows = tableData?.map((row) => {
    const rev = row.orders.overall;
    const revM = row.orders.month;
    const revD = row.orders.today;

    return (
      <TableRow
        key={row.account}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <b>
            {row.name ? `${row.name} ${row.account.slice(-4)}` : row.account}
          </b>
        </TableCell>
        <TableCell>{row.orders.overall.CloseTime.split("T")[0]}</TableCell>
        <TableCell align="right">
          {money
            ? rev
              ? `$${rev.amountMins.toFixed(2)}`
              : "$0.00"
            : rev
            ? `${rev.factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell align="right">
          {money
            ? rev
              ? `$${rev.amountMins.toFixed(2)}`
              : "$0.00"
            : (rev.amountCompound * 100).toFixed(2) + "%"}
        </TableCell>
        <TableCell align="right">
          {money
            ? revM
              ? `$${(revM.amountMins / 100).toFixed(2)}`
              : "$0.00"
            : rev
            ? `${rev.factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell align="right">
          {money
            ? revD
              ? `$${(rev.amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revD
            ? `${rev.factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <div className="flex">
        <FormControlLabel
          value="start"
          checked={money}
          onChange={() => {
            setMoney(!money);
          }}
          control={<Switch color="primary" />}
          label="Показывать в деньгах: "
          labelPlacement="start"
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Название счета</TableCell>
              <TableCell>Дата старта</TableCell>
              <TableCell align="right">Фактический доход</TableCell>
              <TableCell align="right">Абсолютный доход</TableCell>
              <TableCell align="right">Фактический доход (тек мес)</TableCell>
              <TableCell align="right">Фактический доход (тек д)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedRows}
            {all.data && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <b>Итого</b>
                </TableCell>
                <TableCell>~</TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.overall
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall
                    ? `${all.data.overall[
                        all.data.overall.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.overall
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall
                    ? `${(
                        all.data.overall[all.data.overall.length - 1]
                          .amountCompound * 100
                      ).toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.month
                      ? `$${(
                          all.data.month[all.data.month.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.month
                    ? `${all.data.month[
                        all.data.month.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell align="right">
                  {money
                    ? all.data.today[all.data.today.length - 1]
                      ? `$${(
                          all.data.today[all.data.today.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.today[all.data.today.length - 1]
                    ? `${all.data.today[
                        all.data.today.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminOverall;
