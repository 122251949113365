import TodayTable from "../components/TodayTable";

const TodayPage = () => {
  return (
    <div className="defaultLayout">
      <TodayTable />
    </div>
  );
};

export default TodayPage;
