import { useEffect, useState } from "react";
import { getAllAdminTable } from "../../network/TableApi/tableApi";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface IProps {
  type: "factInc" | "amountMins" | "amountCompound";
  desc: string;
}
const AdminChart = (props: IProps) => {
  const [all, setAll] = useState<any>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {
    const params: any = {};
    if (startDate) params.startDate = startDate;
    if (endDate) params.endDate = endDate;

    getAllAdminTable(params).then((data) => {
      // Обработка данных
      const processedData = data.data.overall.map((item: any) => {
        const newItem = { ...item, CloseTime: item.CloseTime.split("T")[0] };
        if (props.type === "amountCompound") {
          newItem.amountCompound = newItem.amountCompound * 100;
        }
        return newItem;
      });
      setAll(processedData);
    });
  }, [props.type, startDate, endDate]);

  const yAxisTickFormatter = (value: number) => {
    if (props.type === "amountCompound" || props.type === "factInc") {
      return `${value}%`;
    }
    return value.toString();
  };

  const tooltipLabelFormatter = (label: string) => {
    return `Дата: ${label}`;
  };

  const tooltipFormatter = (value: any, name: any) => {
    switch (name) {
      case "factInc":
        return [`${value}%`, "Факт. Процент"];
      case "amountCompound":
        return [`${value}%`, "Слож. Процент"];
      case "amountMins":
        return [value, "Доход"];
      default:
        return [value, name];
    }
  };

  return (
    <div className="flex justify-center flex-col">
      <p>{props.desc}</p>
      <div className="mb-4">
        <label>
          Начало периода:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Конец периода:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
      </div>
      <LineChart
        className="mb-8"
        width={700}
        height={400}
        data={all}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line type="monotone" dataKey={props.type} stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="CloseTime" />
        <YAxis tickFormatter={yAxisTickFormatter} />
        <Tooltip
          labelFormatter={tooltipLabelFormatter}
          formatter={tooltipFormatter}
        />
      </LineChart>
    </div>
  );
};

export default AdminChart;
