import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AppRoutes from "../structure/routes";
import { UserCabinet } from "../pages/UserCabinet";
import UserPage from "../pages/UserPage";
import { getHeader } from "../network/UserApi/userApi";
import LoginPage from "../pages/LoginPage";
import { useAuth } from "../providers/AuthProvider";
import RegisterPage from "../pages/RegisterPage";
import Layout from "./Layout";
import TodayPage from "../pages/TodayPage";
import ImportPage from "../pages/ImportPage";
import IncomePage from "../pages/IncomePage";
import DrawdownPage from "../pages/DrawdownPage";
import RobotPage from "../pages/RobotPage";
import PortfolioPage from "../pages/PortfolioPage";
import MyPortfoliosPage from "../pages/MyPortfoliosPage";
import AdminPage from "../pages/AdminPage";
import PublicRobotPage from "../pages/PublicRobotPage";
import PublicLayout from "./PublicLayout";

function AppRouter() {
  const [userInfo, setUserInfo] = useAuth();
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    getHeader()
      .then((r) => {
        setUserInfo(r);
        setIsAuth(true);
      })
      .catch(() => {
        setIsAuth(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuth ? (
    <Router>
      <Routes>
        {!userInfo.id ? (
          <>
            <Route
              path="*"
              element={<Navigate to={AppRoutes.LoginPage} replace={true} />}
            />
            <Route
              path={AppRoutes.RegisterPage}
              element={<RegisterPage />}
            ></Route>
            <Route path={AppRoutes.LoginPage} element={<LoginPage />}></Route>
          </>
        ) : (
          <Route path="/" element={<Layout />}>
            {userInfo.isAdmin && (
              <Route path={AppRoutes.Admin} element={<AdminPage />}></Route>
            )}
            <Route
              path={AppRoutes.UserCabinet}
              element={<UserCabinet />}
            ></Route>
            <Route
              path={AppRoutes.UserPage}
              element={<UserPage header={userInfo} />}
            ></Route>
            <Route path={AppRoutes.TodayPage} element={<TodayPage />}></Route>
            <Route path={AppRoutes.ImportPage} element={<ImportPage />}></Route>
            <Route path={AppRoutes.IncomePage} element={<IncomePage />}></Route>
            <Route
              path={AppRoutes.DrawdownPage}
              element={<DrawdownPage />}
            ></Route>
            <Route path={AppRoutes.RobotPage} element={<RobotPage />}></Route>
            <Route
              path={AppRoutes.MyPortfolio}
              element={<MyPortfoliosPage />}
            ></Route>

            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Route>
        )}
        <Route path="/" element={<PublicLayout />}>
          <Route path={AppRoutes.Portfolio} element={<PortfolioPage />}></Route>
          <Route
            path={AppRoutes.PublicRobot}
            element={<PublicRobotPage />}
          ></Route>
        </Route>
      </Routes>
    </Router>
  ) : (
    <div className="loader"></div>
  );
}
export default AppRouter;
