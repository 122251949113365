import axios from "axios";
import { baseURL } from "../config";

export async function request<T>(url: string, data?: object): Promise<T> {
  try {
    const response = await axios.post<T>(baseURL + url, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (e: any) {
    throw new Error(
      e.response?.data?.message || "An error occurred during the request"
    );
  }
}
