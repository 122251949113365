import { useEffect, useState } from "react";
import { Table, Switch, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { getAllTable, getOverallAccTable } from "../network/TableApi/tableApi";
import { formatDate } from "../utils/functions";
import { TableOverallRes } from "../network/TableApi/tableInterfaces";
import type { ColumnsType } from "antd/es/table";

export interface OverallTableRow {
  key: string;
  account: string;
  name?: string;
  updated?: string;
  isOnline?: boolean;
  startDate: string | null;
  actualIncomeValue: number;
  actualIncomeMoney: number;
  absoluteIncomeValue: number;
  absoluteIncomeMoney: number;
  monthlyIncomeValue: number;
  monthlyIncomeMoney: number;
  dailyIncomeValue: number;
  dailyIncomeMoney: number;
  isTotalRow?: boolean; // Опциональное свойство для итоговой строки
}

const OverallTable = () => {
  const [tableData, setTableData] = useState<Array<TableOverallRes>>([]);
  const [all, setAll] = useState<any>(null);
  const [money, setMoney] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([getOverallAccTable({}), getAllTable({})])
      .then(([resTableData, resAll]) => {
        setTableData(resTableData);
        setAll(resAll);
        setLoading(false);
      })
      .catch(() => {
        setError("Ошибка загрузки данных");
        setLoading(false);
      });
  }, []);

  const getColorStyle = (value: number) => {
    return value >= 0 ? { color: "green" } : { color: "red" };
  };

  const checkAccountStatus = (updated: string) => {
    const updatedTime = new Date(updated);
    const now = new Date();
    const timeDiff = (now.getTime() - updatedTime.getTime()) / 1000 / 60; // разница в минутах
    return timeDiff <= 30; // Если разница <= 30 минут, аккаунт считается онлайн
  };

  // Подготавливаем данные для таблицы
  const dataSource: OverallTableRow[] = tableData.map((row) => {
    // Проверяем наличие row.orders и используем опциональную цепочку
    const overallOrders = Array.isArray(row.orders?.overall)
      ? row.orders.overall
      : [];

    const rev = row.orders.overall;
    const revM = row.orders.month;
    const revD = row.orders.today;

    const isOnline = row.updated ? checkAccountStatus(row.updated) : false;

    const startDate = row.startDate ? row.startDate.split("T")[0] : null;

    // Фактический доход
    const actualIncomeValue = rev?.factInc ?? 0;
    const actualIncomeMoney = rev?.amountMins / 100;

    // Абсолютный доход
    const absoluteIncomeValue = rev?.amountCompound
      ? rev.amountCompound * 100
      : 0;
    const absoluteIncomeMoney = rev?.amountMins / 100;

    // Фактический доход за месяц
    const monthlyIncomeValue = revM?.factInc ?? 0;
    const monthlyIncomeMoney = revM?.amountMins ? revM.amountMins / 100 : 0;

    // Фактический доход за день
    const dailyIncomeValue = revD?.factInc ?? 0;
    const dailyIncomeMoney = revD?.amountMins ? revD.amountMins / 100 : 0;

    return {
      key: row.account,
      account: row.account,
      name: row.name,
      updated: row.updated,
      isOnline,
      startDate,
      actualIncomeValue,
      actualIncomeMoney,
      absoluteIncomeValue,
      absoluteIncomeMoney,
      monthlyIncomeValue,
      monthlyIncomeMoney,
      dailyIncomeValue,
      dailyIncomeMoney,
      isTotalRow: false, // Для обычных строк
    } as OverallTableRow;
  });

  // Добавляем строку "Итого"
  const totalData: OverallTableRow | null = all?.data
    ? {
        key: "total",
        account: "",
        name: "Итого",
        updated: "",
        isOnline: false,
        startDate: "~",
        actualIncomeValue:
          all.data.overall[all.data.overall.length - 1]?.factInc ?? 0,
        actualIncomeMoney:
          all.data.overall[all.data.overall.length - 1]?.amountMins ?? 0,
        absoluteIncomeValue: all.data.overall[all.data.overall.length - 1]
          ?.amountCompound
          ? all.data.overall[all.data.overall.length - 1].amountCompound * 100
          : 0,
        absoluteIncomeMoney:
          all.data.overall[all.data.overall.length - 1]?.amountMins ?? 0,
        monthlyIncomeValue:
          all.data.month[all.data.month.length - 1]?.factInc ?? 0,
        monthlyIncomeMoney: all.data.month[all.data.month.length - 1]
          ?.amountMins
          ? all.data.month[all.data.month.length - 1].amountMins / 100
          : 0,
        dailyIncomeValue:
          all.data.today[all.data.today.length - 1]?.factInc ?? 0,
        dailyIncomeMoney: all.data.today[all.data.today.length - 1]?.amountMins
          ? all.data.today[all.data.today.length - 1].amountMins / 100
          : 0,
        isTotalRow: true, // Для итоговой строки
      }
    : null;

  const dataSourceWithTotal: OverallTableRow[] = totalData
    ? [...dataSource, totalData]
    : dataSource;

  const columns: ColumnsType<OverallTableRow> = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      fixed: "left" as const,
      render: (_: any, record: OverallTableRow) => {
        if (record.isTotalRow) {
          return <b>{record.name}</b>;
        }

        const accountDisplay = record.name
          ? `${record.name} ${record.account.slice(-4)}`
          : record.account;

        return (
          <>
            <Link
              className="text-blue-500 underline"
              to={createPath({
                route: AppRoutes.RobotPage,
                args: [{ key: "id", value: record.account }],
              })}
            >
              <b>{accountDisplay}</b>
            </Link>
            &nbsp;
            {record.updated && (
              <Tooltip
                title={record.isOnline ? "Аккаунт онлайн" : "Аккаунт оффлайн"}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: record.isOnline ? "#3ac47d" : "#d92550",
                    marginLeft: "8px",
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Дата старта",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string | null) => {
        if (date === "~") {
          return "~";
        }
        return date ? formatDate(date) : "-";
      },
    },
    {
      title: "Фактический доход",
      dataIndex: "actualIncome",
      key: "actualIncome",
      align: "right" as const,
      render: (_: any, record: OverallTableRow) => {
        const value = money
          ? record.actualIncomeMoney
          : record.actualIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = getColorStyle(
          record.actualIncomeValue // Используем процентное значение для цвета
        );
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Абсолютный доход",
      dataIndex: "absoluteIncome",
      key: "absoluteIncome",
      align: "right" as const,
      render: (_: any, record: OverallTableRow) => {
        const value = money
          ? record.absoluteIncomeMoney
          : record.absoluteIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = getColorStyle(
          record.absoluteIncomeValue // Используем процентное значение для цвета
        );
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за месяц",
      dataIndex: "monthlyIncome",
      key: "monthlyIncome",
      align: "right" as const,
      render: (_: any, record: OverallTableRow) => {
        const value = money
          ? record.monthlyIncomeMoney
          : record.monthlyIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = getColorStyle(
          record.monthlyIncomeValue // Используем процентное значение для цвета
        );
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
    {
      title: "Фактический доход за день",
      dataIndex: "dailyIncome",
      key: "dailyIncome",
      align: "right" as const,
      render: (_: any, record: OverallTableRow) => {
        const value = money ? record.dailyIncomeMoney : record.dailyIncomeValue;
        const formattedValue = money
          ? `$${value.toFixed(2)}`
          : `${value.toFixed(2)}%`;
        const colorStyle = getColorStyle(
          record.dailyIncomeValue // Используем процентное значение для цвета
        );
        return <span style={colorStyle}>{formattedValue}</span>;
      },
    },
  ];

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="flex items-center mb-4">
        <label className="mr-2">Показывать в деньгах:</label>
        <Switch checked={money} onChange={() => setMoney(!money)} />
      </div>

      <Table<OverallTableRow>
        dataSource={dataSourceWithTotal}
        columns={columns}
        pagination={false}
        rowKey="key"
        loading={loading}
        scroll={{ x: true }}
        locale={{
          emptyText: loading ? "Загрузка..." : "Нет данных для отображения",
        }}
        rowClassName={(record) =>
          record.isTotalRow ? "bg-gray-100 font-bold" : ""
        }
      />
    </div>
  );
};

export default OverallTable;
