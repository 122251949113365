import { useEffect, useState } from "react";
import { Table, Switch, Select, Form } from "antd";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { getIncomeData } from "../network/SumTableApi/SumTableApi";
import {
  AccountIncomeData,
  GetIncomeResponse,
  OverallMonthlyDetail,
  OverallYearlyDetail,
} from "../network/SumTableApi/SumTableInterfaces";

interface IProps {
  accounts?: Array<string>;
}

const YearlyIncome = (props: IProps) => {
  const [tableData, setTableData] = useState<AccountIncomeData[]>([]);
  const [overallMonthly, setOverallMonthly] = useState<OverallMonthlyDetail[]>(
    []
  );
  const [overallYearly, setOverallYearly] = useState<OverallYearlyDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [showPercent, setShowPercent] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getIncomeData({ accounts: props.accounts })
      .then((res: GetIncomeResponse) => {
        setTableData(res.data);
        setOverallMonthly(res.overallMonthly);
        setOverallYearly(res.overallYearly);
        setLoading(false);
      })
      .catch(() => {
        setError("Ошибка загрузки данных");
        setLoading(false);
      });
  }, [props.accounts, selectedYear]);

  const getTextColor = (value: number) => {
    if (value === 0) return "#808080";
    return value > 0 ? "green" : "red";
  };

  const activeMonths = Array.from(
    new Set(
      tableData
        .flatMap((row) => row.details.map((detail) => detail.month))
        .filter((month) => {
          const monthlyDetails = tableData.some((row) =>
            row.details.some(
              (d) => d.month === month && d.year === selectedYear
            )
          );
          return monthlyDetails;
        })
    )
  ).sort((a, b) => a - b);

  // Названия месяцев сокращаем до трёх букв
  const monthNames = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ];

  const columns = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      fixed: "left" as const,
      width: 150, // Устанавливаем фиксированную ширину колонки с названием счета
      render: (_: any, record: any) => {
        if (record.isTotalRow) {
          return <b>{record.name}</b>;
        }

        const accountDisplay = record.account ? record.account.slice(-4) : "";

        return (
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.RobotPage,
              args: [{ key: "id", value: record.account }],
            })}
          >
            <b>
              {record.name
                ? `${record.name} ${accountDisplay}`
                : record.account}
            </b>
          </Link>
        );
      },
    },
    ...activeMonths.map((month) => ({
      title: `${monthNames[month - 1]}${showPercent ? "(%)" : "($)"}`,
      dataIndex: `month_${month}`,
      key: `month_${month}`,
      align: "right" as const,
      className: "",
      width: 80, // Сужаем колонку до 80px
      render: (value: number) => {
        const formattedValue = value.toFixed(2);
        return (
          <span
            style={{
              color: getTextColor(value),
              whiteSpace: "nowrap",
              minWidth: 50,
              display: "inline-block",
            }}
          >
            {formattedValue}
          </span>
        );
      },
    })),
    {
      title: `Общ${showPercent ? "(%)" : "($)"}`,
      dataIndex: "yearlyIncome",
      key: "yearlyIncome",
      align: "right" as const,
      width: 100, // Общая колонка дохода с фиксированной шириной
      className: "bg-gray-50 font-semibold",
      render: (value: number) => {
        const formattedValue = value.toFixed(2);
        return (
          <span
            style={{
              color: getTextColor(value),
              whiteSpace: "nowrap",
              minWidth: 50,
              display: "inline-block",
            }}
            className="font-semibold"
          >
            {formattedValue}
          </span>
        );
      },
    },
  ];

  const dataSource = tableData.map((row) => {
    const data: any = {
      key: row.account,
      name: row.name,
      account: row.account,
      isTotalRow: false,
    };
    activeMonths.forEach((month) => {
      const detail = row.details.find(
        (d) => d.month === month && d.year === selectedYear
      );
      if (detail) {
        const value = showPercent
          ? parseFloat(detail.incomePercentage)
          : parseFloat(detail.netProfit) / 100;
        data[`month_${month}`] = value;
      } else {
        data[`month_${month}`] = 0;
      }
    });

    const overallDetail = row.overall.find((o) => o.year === selectedYear);
    data.yearlyIncome = overallDetail
      ? showPercent
        ? parseFloat(overallDetail.incomePercentage)
        : parseFloat(overallDetail.netProfit) / 100
      : 0;

    return data;
  });

  const overallData = (() => {
    const data: any = {
      key: "overall",
      name: "Общий итог",
      account: null,
      isTotalRow: true,
    };
    activeMonths.forEach((month) => {
      const detail = overallMonthly.find(
        (d) => d.month === month && d.year === selectedYear
      );
      if (detail) {
        const value = showPercent
          ? parseFloat(detail.incomePercentage)
          : parseFloat(detail.netProfit) / 100;
        data[`month_${month}`] = value;
      } else {
        data[`month_${month}`] = 0;
      }
    });

    const detail = overallYearly.find((d) => d.year === selectedYear);
    data.yearlyIncome = detail
      ? showPercent
        ? parseFloat(detail.incomePercentage)
        : parseFloat(detail.netProfit) / 100
      : 0;

    return data;
  })();

  const handleYearChange = (value: number) => {
    setSelectedYear(value);
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ maxWidth: "900px" }}>
      {" "}
      {/* Устанавливаем ограничение ширины */}
      <Form layout="inline" style={{ marginBottom: 16 }}>
        <Form.Item label="Год">
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Показать в процентах">
          <Switch
            checked={showPercent}
            onChange={() => setShowPercent(!showPercent)}
          />
        </Form.Item>
      </Form>
      <Table
        dataSource={[...dataSource, overallData]}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey="key"
        scroll={{ x: true }}
        size="small" // Компактный стиль таблицы
        rowClassName={(record) =>
          record.isTotalRow ? "bg-gray-100 font-bold" : ""
        }
      />
    </div>
  );
};

export default YearlyIncome;
