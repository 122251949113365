import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

interface IProps {
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const PortfolioCheckbox = (props: IProps) => {
  const [checked, setChecked] = useState(props.checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
    setChecked(event.target.checked);
  };
  return (
    <div className="flex items-center">
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <p>{props.name}</p>
    </div>
  );
};

export default PortfolioCheckbox;
