import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { UserAccount } from "../../types/component";
import PortfolioCheckbox from "./PortfolioCheckbox";
import { createPortfolio } from "../../network/TableApi/tableApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface IProps {
  accounts: Array<UserAccount>;
  onCreate: (value: boolean) => void;
}

const PortfolioModal = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = useState<Array<string>>([]);

  function checkCondition(): boolean {
    if (name.length === 0) {
      return false;
    }
    if (!selected[0]) {
      return false;
    }
    return true;
  }

  function handleCreate() {
    createPortfolio({ accounts: selected, name: name }).then((r) => {
      setName("");
      setSelected([]);
      handleClose();
      if (r.success) {
        props.onCreate(true);
      }
    });
  }

  const renderedAccounts = props.accounts.map((account) => (
    <PortfolioCheckbox
      name={account.name || account.account}
      checked={false}
      onChange={(e) => {
        if (e) {
          setSelected([...selected, account.account]);
        } else {
          setSelected(selected.filter((item) => item !== account.account));
        }
      }}
    />
  ));

  return (
    <div>
      <Button onClick={handleOpen}>Новый портфель</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex gap-4">
          <Box sx={style}>
            <TextField
              label="Название портфеля"
              variant="standard"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            {renderedAccounts}
            <Button
              variant="contained"
              disabled={!checkCondition()}
              onClick={() => {
                handleCreate();
              }}
            >
              Сохранить
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default PortfolioModal;
