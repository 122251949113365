import { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { AccountData } from "../network/SumTableApi/SumTableInterfaces";
import { getTodayTableData } from "../network/SumTableApi/SumTableApi";
import { formatDate } from "../utils/dateUtils";

const TodayTable = () => {
  const [tableData, setTableData] = useState<AccountData[]>([]);
  const [overallEarningsMoney, setOverallEarningsMoney] =
    useState<string>("0.00");
  const [overallEarningsPercent, setOverallEarningsPercent] =
    useState<string>("0.00");
  const [latestOrderCreatedAt, setLatestOrderCreatedAt] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true); // Добавлено состояние загрузки

  useEffect(() => {
    getTodayTableData()
      .then((res) => {
        if (res.success) {
          setTableData(res.data);
          setOverallEarningsMoney(res.overallEarningsMoney);
          setOverallEarningsPercent(res.overallEarningsPercent);
          setLatestOrderCreatedAt(res.latestOrderCreatedAt);

          // Обновляем заголовок страницы
          document.title = `Сегодня: ${res.overallEarningsPercent}% / ${
            res.overallEarningsMoney !== "$0.00"
              ? `$${(Number(res.overallEarningsMoney) / 100).toFixed(2)}`
              : overallEarningsMoney
          }`;
        }
      })
      .finally(() => {
        setLoading(false); // Завершаем состояние загрузки после получения данных
      });
  }, [overallEarningsMoney]);

  // Функции для определения цвета по значениям маржи и просадки
  const getMarginColor = (margin: number) => {
    if (margin > 1000) return "green"; // зеленый
    if (margin >= 500) return "orange"; // желтый
    return "red"; // красный
  };

  const getDrawdownColor = (drawdown: number) => {
    if (drawdown < -30) return "red"; // красный
    if (drawdown <= -20) return "orange"; // желтый
    return "green"; // зеленый
  };

  const getColorStyle = (value: number) => {
    return { color: value >= 0 ? "green" : "red" };
  };

  const columns = [
    {
      title: "Название счета",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: AccountData) => (
        <Link
          className="text-blue-500 underline"
          to={createPath({
            route: AppRoutes.RobotPage,
            args: [{ key: "id", value: record.account }],
          })}
        >
          <b>
            {record.name
              ? `${record.name} ${record.account.slice(-4)}`
              : record.account}
          </b>
        </Link>
      ),
    },
    {
      title: "Баланс",
      dataIndex: "balance",
      key: "balance",
      align: "right" as const,
      render: (balance: number) =>
        balance ? `$${(balance / 100).toFixed(2)}` : "н/д",
    },
    {
      title: "Эквити",
      dataIndex: "equity",
      key: "equity",
      align: "right" as const,
      render: (equity: number) =>
        equity ? `$${(equity / 100).toFixed(2)}` : "н/д",
    },
    {
      title: "Сегодня $",
      dataIndex: "earningsMoney",
      key: "earningsMoney",
      align: "right" as const,
      render: (earningsMoney: string) => (
        <span style={getColorStyle(parseFloat(earningsMoney))}>
          {earningsMoney ? (Number(earningsMoney) / 100).toFixed(2) : "н/д"}
        </span>
      ),
    },
    {
      title: "Сегодня %",
      dataIndex: "earningsPercent",
      key: "earningsPercent",
      align: "right" as const,
      render: (earningsPercent: string) => (
        <span style={getColorStyle(parseFloat(earningsPercent))}>
          {earningsPercent}%
        </span>
      ),
    },
    {
      title: "Маржа",
      dataIndex: "margin",
      key: "margin",
      align: "right" as const,
      render: (margin: number) => (
        <span style={{ color: getMarginColor(margin) }}>{margin}</span>
      ),
    },
    {
      title: "Просадка",
      dataIndex: "drawdown",
      key: "drawdown",
      align: "right" as const,
      render: (drawdown: number) => (
        <span style={{ color: getDrawdownColor(drawdown) }}>{drawdown}</span>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between flex-row mb-4">
        <p>
          Заработок за сегодня: <b>{overallEarningsPercent}%</b> /{" "}
          <b>
            $
            {overallEarningsMoney !== "0.00"
              ? (Number(overallEarningsMoney) / 100).toFixed(2)
              : overallEarningsMoney}
          </b>
        </p>
        <p className="text-sm">
          Обновлено:{" "}
          {latestOrderCreatedAt
            ? formatDate(new Date(latestOrderCreatedAt))
            : formatDate(new Date())}
        </p>
      </div>

      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        rowKey="account"
        loading={loading} // Добавлено свойство loading
        locale={{ emptyText: loading ? "Загрузка..." : "Нет данных" }} // Изменение текста при отсутствии данных
      />
    </div>
  );
};

export default TodayTable;
